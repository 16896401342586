<template>
  <home v-if="$root.ready && mode === 'app'">
    <v-snackbar v-model="showUpdateSnack" :timeout="-1">
      {{ $t(updating ? 'updating' : 'update_available') }}
      <template v-slot:action>
        <v-btn v-if="!updating" color="green" text @click="updateApp">OK</v-btn>
      </template>
    </v-snackbar>
  </home>

  <print v-else-if="$root.ready && mode === 'print' && $root.isAdmin"/>

  <sign-up-in v-else-if="$root.userId === false"/>

  <v-app v-else>
    <v-overlay :value="true" opacity="0">
      <v-progress-circular
        color="primary"
        indeterminate
        size="50"
      ></v-progress-circular>
    </v-overlay>
  </v-app>
</template>

<script>
export default {
  name: 'App',
  components: {
    Home: () => import('@/views/Home'),
    Print: () => import('@/views/Print'),
    SignUpIn: () => import('@/views/SignUpIn'),
    ImgCmp: () => import('@/components/base/ImgCmp')
  },
  data () {
    return {
      mode: null,
      showUpdateSnack: false,
      updating: false
    }
  },
  methods: {
    updateApp () {
      this.updating = true
      // Make sure we only send a 'skip waiting' message if the SW is waiting
      if (!this.registration || !this.registration.waiting) {
        this.showUpdateSnack = false
        return
      }
      // Send message to SW to skip the waiting and activate the new SW
      this.registration.waiting.postMessage({ type: 'SKIP_WAITING' })
    }
  },
  created () {
    // const urlParams = new URLSearchParams(window.location.search)
    // urlParams.has('mode') ? urlParams.get('mode') : 'app'
    this.mode = window.location.pathname.substr(1) || 'app'
    
    document.addEventListener('swUpdated', (event) => {
      this.registration = event.detail
      this.showUpdateSnack = true
      if (this.$cfg.autoUpdate) {
        this.updateApp()
      }
    }, { once: true })

    if (navigator.serviceWorker !== undefined) { // may not be available in private mode or local
      let reloading = false
      navigator.serviceWorker.addEventListener('controllerchange', () => {
        // We'll also need to add 'reloading' to our data originally set to false.
        if (reloading) return
        reloading = true
        // Here the actual reload of the page occurs
        window.location.reload()
      })
    }
  }
}
</script>
