export default {
  de: {
    camera: 'Kamera',
    confirm_title: 'Bestätigung',
    confirm_ok: 'OK',
    confirm_cancel: 'Abbrechen',
    file: 'Datei',
    files: 'Dateien',
    files_add: 'Dateien hinzufügen',
    gallery: 'Galerie',
    has_new: 'Zum Aktualisieren tippen',
    hidden_events: '1 versteckter Event | {n} versteckte Events',
    hidden_post: '1 versteckter Beitrag | {n} versteckte Beiträge',
    img: 'Bild',
    imgs_more: 'Weitere Bilder',
    item_deleted: 'Dieser Eintrag wurde gelöscht',
    no_more_entries: 'Keine weiteren Beiträge',
    moderated_post: 'Moderiert (wird geprüft)',
    remove: 'Entfernen',
    scheduled_post: 'Geplant',
    selfie: 'Selfie',
    show_more: 'Mehr',
    tags: 'Themen',
    tags_info: 'Es stehen neue Themen zur Auswahl',
    tag: 'Thema',
    telm: 'Tel Mobil',
    telp: 'Tel Privat',
    telw: 'Tel Geschäft',
    update_available: 'Ein Update ist verfügbar',
    updating: 'Update wird installiert..',
    // notifications
    message_direct: 'Direktnachricht',
    message_group: 'Nachricht in Gruppenchat',
    new_post: 'Neuer Beitrag',
    new_post_review: 'Neuer Beitrag zur Prüfung',
    comment_my_post: 'Dein Beitrag wurde kommentiert',
    comment_my_comment: 'Ein Beitrag den du kommentiert hast wurde kommentiert',
    mention_me: 'Du wurdest erwähnt',
    signup_review: 'Neue Registration zur Prüfung',

    // FB auth errors
    // err_auth_captcha_check_failed: '?'
    err_auth_email_already_in_use: 'Diese E-Mail Adresse wird bereits verwendet',
    err_auth_invalid_email: 'Die E-Mail Adresse ist ungültig',
    err_auth_invalid_phone_number: 'Die Mobile-Nr ist ungültig',
    err_auth_user_disabled: 'Konto deaktiviert',
    err_auth_user_not_found: 'Konto nicht gefunden',
    err_auth_wrong_password: 'Passwort ungültig'
  }
}
